import s from './LoginPage.module.scss'
import LoginForm from "@pages/LoginPage/LoginForm/index.jsx";
import RegistrationForm from "@pages/LoginPage/RegisterForm/index.jsx";
import {useEffect, useState} from "react";
import {scrollToTop} from "@hooks/scrollToTop.js";

const LoginPage = () => {

    const [currentForm, setCurrentForm] = useState('Login')

    useEffect(() => {
        scrollToTop()
    }, [])

    const forms = {
        loginForm: 'Login',
        registrationForm: 'Registration'
    }

    const handleCurrentTab = (tab) => {
        setCurrentForm(tab)
    }


    return (
        <div className={s.loginWrapper}>
            <div className={s.login}>
                <div className={s.loginTabs}>
                    <button className={currentForm === 'Login' ? s.activeTab : null} onClick={() => {
                        handleCurrentTab('Login')
                    }}>Вход
                    </button>
                    <button className={currentForm === 'Registration' ? s.activeTab : null} onClick={() => {
                        handleCurrentTab('Registration')
                    }}>Регистрация
                    </button>
                </div>
                {currentForm === 'Login' ? <LoginForm/> : <RegistrationForm/>}
            </div>
        </div>

    );
};

export default LoginPage;