export const serviceSliderItem =[
    {
        id: 1,
        title: 'Услуги с высоким рейтингом',
        img: 'photo4nik',
    },
    {
        id: 2,
        title: 'Услуги, набирающие популярность',
        img: 'complukter',
    },
    {
        id: 3,
        title: 'Услуги с высоким рейтингом',
        img: 'photo4nik',
    },
    {
        id: 4,
        title: 'Услуги, набирающие популярность',
        img: 'complukter',
    }
]