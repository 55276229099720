export const dropDownNavMenuData = [
    {
        id: 1,
        name: 'Товары',
        tabs: [
            {
                id: 1,
                name: 'Картины',
                products: [
                    {
                        id: 1,
                        name: 'Картина 1',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                    {
                        id: 2,
                        name: 'Картина 2',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                    {
                        id: 3,
                        name: 'Абстракция',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                    {
                        id: 4,
                        name: 'Портрет',
                        img: 'portrait',
                        link: '/portrait',
                    },
                    {
                        id: 5,
                        name: 'Пейзаж',
                        img: 'landscape',
                        link: '/landscape',
                    },
                    {
                        id: 6,
                        name: 'Картина 3',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                    {
                        id: 7,
                        name: 'Картина 3',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                    {
                        id: 8,
                        name: 'Картина 3',
                        img: 'abstraction',
                        link: '/abstractions',
                    },
                ],
            },
            {
                id: 2,
                name: 'Фотографии',
                products: [
                    {
                        id: 1,
                        name: 'Фото 1',
                    },
                    {
                        id: 2,
                        name: 'Фото 2',
                    },
                    {
                        id: 3,
                        name: 'Фото 3',
                    },
                ],
            },
            {
                id: 3,
                name: 'Скульптуры',
                products: [
                    {
                        id: 1,
                        name: 'Скульптура 1',
                    },
                    {
                        id: 2,
                        name: 'Скульптура 2',
                    },
                    {
                        id: 3,
                        name: 'Скульптура 3',
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        name: 'Услуги',
        tabs: [
            {
                id: 1,
                name: 'Картины на заказ',
                products: [
                    {
                        id: 1,
                        name: 'Картинаa 1',
                    },
                    {
                        id: 2,
                        name: 'Картинаa 2',
                    },
                    {
                        id: 3,
                        name: 'Картинаa 3',
                    },
                ],
            },
            {
                id: 2,
                name: 'Фотографии на заказ',
                products: [
                    {
                        id: 1,
                        name: 'Фотоо 1',
                    },
                    {
                        id: 2,
                        name: 'Фотоо 2',
                    },
                    {
                        id: 3,
                        name: 'Фотоо 3',
                    },
                ],
            },
            {
                id: 3,
                name: 'Скульптуры на заказ',
                products: [
                    {
                        id: 1,
                        name: 'Скульптураа 1',
                    },
                    {
                        id: 2,
                        name: 'Скульптураа 2',
                    },
                    {
                        id: 3,
                        name: 'Скульптураа 3',
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        name: 'Творцы',
        tabs: [
            {
                id: 1,
                name: 'Картины от автора',
                products: [
                    {
                        id: 1,
                        name: 'Картинааа 1',
                    },
                    {
                        id: 2,
                        name: 'Картинааа 2',
                    },
                    {
                        id: 3,
                        name: 'Картинааа 3',
                    },
                ],
            },
            {
                id: 2,
                name: 'Фотографии от автора',
                products: [
                    {
                        id: 1,
                        name: 'Фотооо 1',
                    },
                    {
                        id: 2,
                        name: 'Фотооо 2',
                    },
                    {
                        id: 3,
                        name: 'Фотооо 3',
                    },
                ],
            },
            {
                id: 3,
                name: 'Скульптуры от автора',
                products: [
                    {
                        id: 1,
                        name: 'Скульптурааа 1',
                    },
                    {
                        id: 2,
                        name: 'Скульптурааа 2',
                    },
                    {
                        id: 3,
                        name: 'Скульптурааа 3',
                    },
                ],
            },
        ],
    },
]
