export const productsSliderItem =[
    {
        id: 1,
        title: 'Товары с высоким рейтингом',
        img: 'cubes',
    },
    {
        id: 2,
        title: 'Товары, набирающие популярность',
        img: 'flower',
    },
    {
        id: 3,
        title: 'Товары ',
        img: 'cubes',
    },
    {
        id: 4,
        title: 'Товары, набирающие ',
        img: 'flower',
    }
]