import React, {useContext, useMemo} from 'react';
import s from './AuthorInfo.module.scss'
import {ProductCardContext} from "@context/ProductCardContext/ProductCardContext.js";
import UserTabInfo from "@components/ProductCardTabs/UserTabInfo/UserTabInfo.jsx";
import {fakeAuthorData} from "@data/FakeAuthorData.js";
import {productCardData} from "@pages/ProductCardPage/productCardData/productCardData.js";


const AuthorInfo = () => {

    const author = useMemo(() => {
        return fakeAuthorData.find((el) => el.authorName === productCardData.author_name)
    }, [productCardData, fakeAuthorData])


    const {
        authorImg,
        authorName,
        authorJob,
        authorRating,
        authorDescription
    } = author

    return (
        <div className={s.authorTab}>
            <UserTabInfo authorImg = {authorImg} authorName = {authorName} authorJob = {authorJob} authorRating={authorRating}/>
            <div className={s.authorTabRight}>
                <div className={s.authorTabRightDescription}>
                   <span>О себе:</span> <p>{authorDescription}</p>
                </div>
            </div>
        </div>
    );
};

export default AuthorInfo;