import s from './PopularCategoriesSection.module.scss'
import PictureStyledProductCard from '@components/PictureStyledProductCard'
import { popularCategoriesSection } from './data/popularCategoriesSection.js'

const PopularCategoriesSection = () => {
	return (
		<div className={s.popularCategories}>
			<h2>Популярные категории</h2>
			<div className={s.wrapper}>
				{popularCategoriesSection.map((item) => (
					<PictureStyledProductCard {...item} key={item.id} />
				))}
			</div>
		</div>
	)
}

export default PopularCategoriesSection
