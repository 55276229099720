import s from './LoginForm.module.scss'
import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import {BsGoogle} from 'react-icons/bs'
import {FaTelegramPlane} from 'react-icons/fa'

const LoginForm = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const {
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset
    } = useForm({
        mode: "onBlur",
    })


    const onSubmit = (data) => {
        alert(JSON.stringify(data))
        reset()
    }
    const togglePassword = () => {
        setPasswordShown(p => !p);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label className={s.input}>
                Email / номер телефона

                <input {...register("emailNumber", {
                    required: "Поле обязательно к заполнению",
                    minLength: {
                        value: 5,
                        message: "Минимум 5 символов"
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Некорректный Email"
                    }
                })} className={s.number} type="tel"/>

                {errors?.emailNumber && <span className={s.errorSpan}>{errors?.emailNumber?.message}</span> || ''}
            </label>
            <label className={s.input}>
                Пароль
                <div className={s.inputWrapper}>
                    <input  {...register("password", {
                        required: "Поле обязательно к заполнению",
                        minLength: {
                            value: 7,
                            message: "Минимум 7 символов"
                        },
                    })} className={s.number} type={passwordShown ? "text" : "password"}/>

                    <button type="button" className={s.showPass} onClick={togglePassword}>{passwordShown ?
                        <AiOutlineEyeInvisible/> :
                        <AiOutlineEye/>}</button>
                </div>
                {errors?.password && <span className={s.errorSpan}>{errors?.password?.message}</span> || ''}

                <label className={s.checkbox}>
                    <input type="checkbox"/>
                    <span>Запомнить пароль</span>
                </label>
            </label>
            <div className={s.bottomWrapper}>
                <button className={s.submitBtn} disabled={!isValid} type="submit">Войти</button>

                <div className={s.resetPass}>Забыли пароль?</div>

                <ul className={s.loginWith}>
                    <li><BsGoogle className={s.google}/></li>
                    <li><img className={s.vk}
                             src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/VK_logo.svg/768px-VK_logo.svg.png?20200611104337"
                             alt=""/></li>
                    <li><FaTelegramPlane className={s.telegram}/></li>
                </ul>

            </div>
        </form>
    );
};

export default LoginForm;