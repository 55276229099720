import s from './MainSections.module.scss'
import PictureStyledProductCard from '@components/PictureStyledProductCard'
import {mainSectionsData} from './data/mainSectionsData.js'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

const MainSections = () => {
    const breakPoints = [{width: 1, itemsToShow: 3}]

    return (
        <div className={s.mainSectionsWrapper}>
            <h2>Основные разделы</h2>
            <Swiper
                className={s.swiper}
                modules={[Navigation]}
                navigation
                spaceBetween={15}
                slidesPerView={3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {mainSectionsData.map((item) => (
                    <SwiperSlide key={item.id}>
                        <PictureStyledProductCard {...item} />
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    )
}

export default MainSections
