export const popularCategoriesSection = [
	{
		id: 1,
		img: 'popular-4',
		title: '3D',
	},
	{
		id: 2,
		img: 'popular-5',
		title: 'Музыка',
	},
	{
		id: 3,
		img: 'popular-6',
		title: 'Изделия из гипса',
	},
]
