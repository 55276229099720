export const eventsSectionData = [
	{
		id: 1,
		title: 'САМЫЕ ЗНАЧИМЫЕ СОБЫТИЯ',
		text: `Не упустите возможность узнать самое главное про нашу
  платформу! Не упустите возможность узнать самое главное про
  нашу платформу! Не упустите возможность узнать самое
  главное про нашу платформу.`,
		img: 'event-1',
		link: '#',
	},
	{
		id: 2,
		title: 'САМЫЕ НЕЗНАЧИМЫЕ СОБЫТИЯ',
		text: `Не упустите возможность узнать самое главное про нашу
  платформу! Не упустите возможность узнать самое главное про
  нашу платформу! Не упустите возможность узнать самое
  главное про нашу платформу.`,
		img: 'event-2',
		link: '#',
	},
	{
		id: 3,
		title: 'САМЫЕ КРУТЫЕ СОБЫТИЯ',
		text: `Не упустите возможность узнать самое главное про нашу
  платформу! Не упустите возможность узнать самое главное про
  нашу платформу! Не упустите возможность узнать самое
  главное про нашу платформу.`,
		img: 'event-1',
		link: '#',
	},
	{
		id: 4,
		title: 'САМЫЕ ПЛОХИЕ СОБЫТИЯ',
		text: `Не упустите возможность узнать самое главное про нашу
  платформу! Не упустите возможность узнать самое главное про
  нашу платформу! Не упустите возможность узнать самое
  главное про нашу платформу.`,
		img: 'event-2',
		link: '#',
	},
	{
		id: 5,
		title: 'САМЫЕ ОФИГЕННЫЕ СОБЫТИЯ',
		text: `Не упустите возможность узнать самое главное про нашу
  платформу! Не упустите возможность узнать самое главное про
  нашу платформу! Не упустите возможность узнать самое
  главное про нашу платформу.`,
		img: 'event-1',
		link: '#',
	},
]
