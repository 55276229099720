import s from './PictureStyledProductCard.module.scss'
import HoverSearchLink from '@UI/HoverSearchLink'

const PictureStyledProductCard = ({ img, title }) => {
	return (
		<a className={s.pictureStyledProductCard} href='#'>
			<div className={s.pictureStyledProductCardImg}>
				<img src={`images/popularCategoriesSection/${img}.jpg`} alt={img} />
			</div>
			<h3>{title}</h3>
			<HoverSearchLink height320={'height320'} />
		</a>
	)
}

export default PictureStyledProductCard
