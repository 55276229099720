import s from './DropDownNavMenuItem.module.scss'
import {useContext, useRef} from 'react'
import MenuButton from '@UI/MenuButton'
import { DropDownNavMenuContext } from '../context/DropDownNavMenuContext'
import HoverSearchLink from "@UI/HoverSearchLink/index.jsx";
import useOutside from "@hooks/useOutside.jsx";

const DropDownNavMenuItem = ({ item }) => {
  const { setActiveTab, activeTab } = useContext(DropDownNavMenuContext)
  const setActiveTabHandler = (e) => {
    setActiveTab(e.target.innerText)
  }

  return (
    <div className={s.dropDownNavMenuItem}>
      <div className={s.wrapper}>
        <h3>{item.name}</h3>
        <ul className={s.tabs}>
          {item.tabs.map((item) => (
            <li key={item.id}>
              <button className={activeTab === item.name ? s.activeTab : ''} onClick={setActiveTabHandler}>{item.name}</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default DropDownNavMenuItem
