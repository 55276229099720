// import CarouselSlider from '@components/Carousel'
import {productsSliderItem} from "@components/ProductsSliderSection/data/productsSliderData"
import {serviceSliderItem} from "@components/ProductsSliderSection/data/serviceSliderData"
import ProductsSliderChildItem from "@components/ProductsSliderChildItem/ProductsSliderChildItem.jsx";
import ServiceSliderChildItem from '@components/ServiceSliderChildItem/ServiceSliderChildItem.jsx'
import "@components/ProductsSliderSection/ProductSlider.css"
import cn from 'classnames'
import s from './ProductsSliderSection.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import {Navigation} from "swiper";

const ProductsSliderSection = () => {
    const swiperStyles1 = cn('mySwiper', 'mySwiper1', s.swiper)
    const swiperStyles2 = cn('mySwiper', 'mySwiper2', s.swiper)
    return (


        <div className={s.wrapper}>
            {/* <CarouselSlider>
        <ProductsSliderItem />
        <ProductsSliderItem smallLeft={true} />
        <ProductsSliderItem />
      </CarouselSlider>
      <CarouselSlider>
        <ServiceSliderItem smallLeft={true} />
        <ServiceSliderItem />
        <ServiceSliderItem smallLeft={true} />
      </CarouselSlider> */}

            <Swiper
                className={swiperStyles1}
                navigation={true}
                modules={[Navigation]}
                slidesPerView={"auto"}
                spaceBetween={20}
            >
                {productsSliderItem.map(({title, img, id}) => (

                    <SwiperSlide key={id}>
                        <ProductsSliderChildItem title={title} img={img}/>
                    </SwiperSlide>

                ))}
            </Swiper>


            <Swiper
                className={swiperStyles2}
                navigation={true}
                modules={[Navigation]}
                slidesPerView={"auto"}
                spaceBetween={20}

            >
                {serviceSliderItem.map(({title, img, id}) => (


                    <SwiperSlide key={`key_${id}`}>
                        <ServiceSliderChildItem title={title} img={img} key={id}/>
                    </SwiperSlide>

                ))}
            </Swiper>
        </div>
    )
}
export default ProductsSliderSection
 