import s from "./UserTabInfo.module.scss";
import {Link} from "react-router-dom";
import HoverSearchLink from "@UI/HoverSearchLink/index.jsx";
import {BsFillStarFill} from "react-icons/bs";
import React from "react";

const UserTabInfo = ({authorImg, authorName, authorJob, authorRating}) => {
    return (
        <div className={s.userTabInfo}>
            <div className={s.userInfo}>
                <div className={s.userInfoImg}>
                    <Link className={s.toProfileImg} to='/profile'> <HoverSearchLink maxRounded small/> <img
                        src={`/images/authors/${authorImg}.jpg`}/></Link>

                    <Link className={s.toProfile} to='/profile'>В профиль</Link>
                </div>
                <div className={s.userInfoInfo}>
                    <span className={s.userInfoInfoName}>{authorName}</span>
                    <span className={s.userInfoInfoJob}>{authorJob}</span>
                    <span className={s.userInfoInfoRating}> <BsFillStarFill className={s.ratingStar}/>{authorRating.toFixed(1)}</span>
                </div>
            </div>
        </div>
    );
};

export default UserTabInfo;