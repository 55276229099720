import s from './DropDownNavMenu.module.scss'
import { useContext, useState } from 'react'
import { dropDownNavMenuData } from './data/dropDownNavMenuData'
import { AnimatePresence, motion } from 'framer-motion'
import { AiOutlineClose } from 'react-icons/ai'
import DropDownNavMenuItem from './DropDownNavMenuItem'
import { HeaderContext } from '@context/headerContext/HeaderContext.js'
import { DropDownNavMenuContext } from './context/DropDownNavMenuContext'
import { Link } from 'react-router-dom'
import HoverSearchLink from '@UI/HoverSearchLink/index.jsx'
import useOutside from '@hooks/useOutside.jsx'

const DropDownNavMenu = ({ isOnTop }) => {
  const { ref, isShow, handleVisibility } = useContext(HeaderContext)
  const [activeTab, setActiveTab] = useState('Картины')

  return (
    <DropDownNavMenuContext.Provider value={{ setActiveTab, activeTab }}>
      <AnimatePresence initial={false}>
        {isShow ? (
          <motion.div
            ref={ref}
            className={`${s.dropDownNavMenu} ${isOnTop ? '' : s.notOnTop}`}
            initial={{ y: 0, x: '-50%', opacity: 0, zIndex: -10 }}
            animate={{ y: 0, x: '-50%', opacity: 1, zIndex: 1 }}
            exit={{ y: 0, x: '-50%', opacity: 0, zIndex: -10 }}
            transition={{
              type: 'spring',
              stiffness: 650,
              damping: 32,
              duration: 0.2,
            }}
          >
            <div className={s.navWrapper}>
              <nav className={s.wrapper}>
                <ul>
                  {dropDownNavMenuData.map((item) => (
                    <DropDownNavMenuItem item={item} key={item.id} />
                  ))}
                </ul>
              </nav>
              <div className={s.products}>
                <ul className={s.productsList}>
                  {dropDownNavMenuData.map((item) => (
                    <div key={item.id}>
                      {item.tabs.map((item) => (
                        <div className={s.productItems} key={item.id}>
                          {item.name === activeTab
                            ? item.products.map((item) => (
                                <li key={item.id}>
                                  <Link to={item.link}>
                                    <div className={s.imgWrapper}>
                                      <img
                                        src={`./images/menu/${item.img}.jpg`}
                                        alt=""
                                      />
                                      <HoverSearchLink
                                        mdRounded={true}
                                        small={true}
                                      />
                                    </div>
                                    {item.name}
                                  </Link>
                                </li>
                              ))
                            : null}
                        </div>
                      ))}
                    </div>
                  ))}
                </ul>
              </div>
              <button onClick={handleVisibility}>
                <AiOutlineClose className={s.closeBtn} />
              </button>
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </DropDownNavMenuContext.Provider>
  )
}

export default DropDownNavMenu
